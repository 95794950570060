// @flow
import './DarkModeToggle.module.scss';

import React, { useEffect, useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

import {
  addThemeListener,
  getTheme,
  removeThemeListener,
  setPreferredTheme,
} from '../../utils/darkmode';

const DarkModeToggle = () => {
  if (typeof window === 'undefined') {
    // Never server-side render this, since we can't determine
    // the correct initial state until we get to the client.
    return null;
  }

  const [checked, setChecked] = useState(getTheme() === 'dark');

  const toggleDarkMode = (checked: boolean) => {
    setChecked(checked);
    setPreferredTheme(checked ? 'dark' : 'light');
  };

  useEffect(() => {
    const listener = () => {
      setChecked(getTheme() === 'dark');
    };
    addThemeListener(listener);

    return () => {
      removeThemeListener(listener);
    };
  }, [setChecked]);

  return <DarkModeSwitch checked={checked} onChange={toggleDarkMode} moonColor={'#487eb0'} sunColor={'#e58e26'} />;
};

export default DarkModeToggle;