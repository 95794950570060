// @flow strict
import classNames from 'classnames/bind';
import React from 'react';
import { Link } from 'gatsby';
import styles from './Menu.module.scss';
import { useSiteMetadata } from '../../../hooks';

const cx = classNames.bind(styles);

type Props = {
  horizontal: boolean,
  bold: boolean,
  noMargin: boolean,
};

const Menu = ({ horizontal, bold, noMargin }: Props) => {
  const { menu } = useSiteMetadata();

  return (
    <nav
      className={cx({
        menu: true,
        horizontal,
        'no-margin': noMargin,
      })}
    >
      <ul className={styles['menu__list']}>
        {menu.map(item => (
          <li className={styles['menu__list-item']} key={item.path}>
            <Link
              to={item.path}
              className={cx({
                'menu__list-item-link': true,
                bold,
              })}
              activeClassName={styles['menu__list-item-link--active']}
              partiallyActive={item.path !== '/'}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};


export default Menu;
